<template>
  <v-row justify="center">
    <v-col cols="11">
      <v-data-table
        :headers="eventsHeaders"
        :items="this.eventsFormatted"
        :loading="eventsLoader"
        item-key="name"
        class="elevation-0"
        v-if="this.eventsFormatted"
        hide-default-footer
        disable-sort
      >
        <template v-slot:[`header.event`]="{ header }">
          <span class="wc-overline text-primary-variant font-weight-black">
            {{ header.text }}
          </span>
        </template>
        <template v-slot:[`header.date`]="{ header }">
          <span class="wc-overline text-primary-variant font-weight-black">
            {{ header.text }}
          </span>
        </template>
        <template v-slot:[`header.state`]="{ header }">
          <span class="wc-overline text-primary-variant font-weight-black">
            {{ header.text }}
          </span>
        </template>
        <template v-slot:[`item.event`]="{ value }">
          <span class="wc-caption-2 text-primary-variant font-weight-bold">
            {{ value }}
          </span>
        </template>
        <template v-slot:[`item.date`]="{ value }">
          <span class="wc-caption-2 text-primary-variant font-weight-bold">
            {{ value }}
          </span>
        </template>
        <template v-slot:[`item.state`]="{ value }">
          <v-chip label :color="value == 'Publico' ? 'blue lighten-3' : ''">
            <span
              class="
                wc-caption-2
                text-primary-variant
                font-weight-bold
                text-uppercase
              "
            >
              {{ value }}
            </span>
          </v-chip>
        </template>
        <template v-slot:[`item.actions`]="{ value }">
          <v-row justify="center">
            <v-btn
              text
              :to="`/mi-evento/asistentes/${value}`"
              :disabled="
                !user.permissions.Event || !user.permissions.Event.update
              "
            >
              <v-icon left>mdi-account-check-outline</v-icon>
              <span class="wc-caption-2 text-primary-variant font-weight-bold"
                >Check-In</span
              >
            </v-btn>
            <v-btn
              text
              :to="`/mi-evento/micrositio/${value}`"
              :disabled="
                !user.permissions.Event || !user.permissions.Event.update
              "
            >
              <v-icon left>mdi-cog-play-outline</v-icon>
              <span class="wc-caption-2 text-primary-variant font-weight-bold"
                >Gestionar</span
              >
            </v-btn>
            <v-btn
              text
              v-clipboard:copy="shareItem(value)"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <v-icon left>mdi-link</v-icon>
              <span class="wc-caption-2 text-primary-variant font-weight-bold"
                >Copiar liga</span
              >
            </v-btn>
          </v-row>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>
<script>
import qs from "query-string";
import Endpoints from "@/share/Endpoints";
import { mapState, mapMutations, mapActions } from "vuex";
export default {
  data() {
    return {
      eventsLoader: false,
      eventsSearchData: {
        name: "",
        page: 1,
        perPage: 100,
      },
      eventsHeaders: [
        {
          text: "Nombre del evento",
          align: "left",
          value: "event",
        },
        {
          text: "Fecha",
          align: "center",
          value: "date",
        },
        {
          text: "Estado",
          align: "center",
          value: "state",
        },
        {
          text: "",
          align: "center",
          value: "actions",
        },
      ],
    };
  },
  computed: {
    ...mapState("event", ["organizationEvents"]),
    ...mapState("user", ["user"]),
    eventsFormatted() {
      let events = [];
      if (!this.organizationEvents) return events;
      for (let i = 0; i < this.organizationEvents.length; i++) {
        const event = this.organizationEvents[i];
        events.push({
          event: event.name,
          date: this.formatDate(event.dateConfiguration.startDate),
          state: event.published ? "Publico" : "Oculto",
          actions: event._id,
        });
      }
      return events;
    },
  },
  methods: {
    ...mapActions("event", ["fetchOrganizationEvents", "publishEvent"]),
    ...mapMutations("notification", ["show"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    async $_publishEvent(eventId) {
      try {
        this.loading();
        const endpoint = qs.stringifyUrl({
          url: Endpoints.publishEvent.replace(/:eventId/, eventId),
        });
        const response = await this.axios.put(endpoint);
        if (response.status > 199 && response.status < 300) {
          await this.fetchOrganizationEvents(this.eventsSearchData);
          this.loaded();
          this.show({
            text: "¡Evento publicado correctamente!",
            color: "primary",
          });
        } else {
          this.show({
            text: "¡Ha ocurrido un problema en la publicación del evento!",
            color: "error",
          });
        }
        this.loaded();
      } catch (error) {
        setTimeout(() => {
          this.show({
            text: error.message
              ? error.message
              : "¡Ha ocurrido un problema al querer publicar el evento!",
            color: "error",
          });
          this.loaded();
        }, 1000);
      }
      /*let dataEvent = {
        id: eventInfo._id,
        published: !eventInfo.published
      }
      await this.publishEvent(dataEvent);
      await this.fetchOrganizationEvents(this.eventsSearchData);*/
    },
    async $_hideEvent(eventId) {
      try {
        this.loading();
        const endpoint = qs.stringifyUrl({
          url: Endpoints.hideEvent.replace(/:eventId/, eventId),
        });
        const response = await this.axios.put(endpoint);
        if (response.status > 199 && response.status < 300) {
          await this.fetchOrganizationEvents(this.eventsSearchData);
          this.show({
            text: "¡Evento ocultado correctamente!",
            color: "primary",
          });
          this.loaded();
        } else {
          this.show({
            text: "¡Ha ocurrido un problema al ocultar el evento!",
            color: "error",
          });
        }
        this.loaded();
      } catch (error) {
        setTimeout(() => {
          this.show({
            text: error.message
              ? error.message
              : "¡Ha ocurrido un problema al querer ocultar el evento!",
            color: "error",
          });
          this.loaded();
        }, 1000);
      }
      /*let dataEvent = {
        id: eventInfo._id,
        published: !eventInfo.published
      }
      await this.publishEvent(dataEvent);
      await this.fetchOrganizationEvents(this.eventsSearchData);*/
    },
    formatDate(currentDate) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      const monthNames = [
        "ENE",
        "FEB",
        "MAR",
        "ABR",
        "MAY",
        "JUN",
        "JUL",
        "AGO",
        "SEP",
        "OCT",
        "NOV",
        "DIC",
      ];

      let date = new Date(currentDate);
      //console.log(date.toLocaleDateString('es-MX', options));

      let day = date.getDate().toString().padStart(2, "0");
      let month = monthNames[date.getMonth()];
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    /**
     * Función que devuelve un link para compartir el evento seleccionado.
     * @param { string } id Identificador único de un evento.
     */
    shareItem(id) {
      return `${window.location.origin}/micrositio/${id}`;
    },
    onCopy: function (e) {
      this.show({
        text: "¡Se ha copiado la url del evento al portapapeles!",
        color: "primary",
      });
    },
    onError: function (e) {
      this.show({
        text: "¡Ha ocurrido un problema al copiar la url del evento!",
        color: "error",
      });
    },
  },
  async mounted() {
    await this.fetchOrganizationEvents(this.eventsSearchData);
  },
};
</script>
<style scoped>
table,
tr,
td {
  border: none !important;
}
</style>