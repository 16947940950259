<template>
  <v-dialog
    transition="dialog-bottom-transition"
    width="700"
    style="border-radius: 0px"
  >

    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="documentFields"
        icon
        color="blue"
        v-bind="attrs"
        v-on="on"
        :disabled="
          documents.length == 0 ||
          !user.permissions.Event ||
          !user.permissions.Event.update
        "
      >
      <v-tooltip top>
        <template #activator="{on, attrs}">
          <v-icon v-on="on" v-bind="attrs"
          >mdi-paperclip</v-icon>
        </template>
        <span>Documentos</span>
      </v-tooltip>
      </v-btn>

      <v-btn
        v-else-if="!documentFields"
        icon
        color="blue"
        v-bind="attrs"
        v-on="on"
        :disabled="
          documents.length == 0 ||
          !user.permissions.Event ||
          !user.permissions.Event.update
        "
      >
      <v-tooltip top>
        <template #activator="{on, attrs}">
          <v-icon v-on="on" v-bind="attrs">
            mdi-file-certificate-outline
          </v-icon>
        </template>
        <span>Constancias/Diploma</span>
      </v-tooltip>
      </v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card class="pa-14" style="border-radius: 0px" color="white">
        <v-row class="mb-5">
          <v-col cols="auto" class="d-flex align-center py-2">
            <span
              class="ma-0"
              style="font-weight: 800; text-transform: uppercase"
              >{{ item.name }} - {{ item.lastName }}</span
            >
          </v-col>
          <v-col cols="auto" class="d-flex align-center py-2">
            <p class="ma-0">
              <span style="color: '#35404c'; font-weight: bold;">E-MAIL - </span
              >{{ item.email }}
            </p>
          </v-col>
        </v-row>
        <v-card-text class="px-0">
          <span style="color: #35404c; font-weight: 900; font-size: 18px"
            >Lista de documentos</span
          >
          <v-divider
            class="mt-1"
            style="background: #35404c; border: 1px solid #35404c"
          />
          <v-list>
            <v-list-item-group>
              <v-list-item dense v-for="(doc, i) in documents" :key="i">
                <v-list-item-content>
                  <span
                    class="text-capitalize"
                    v-text="doc.name"
                    style="font-size: 15px; color: #35404c; font-weight: 800"
                  ></span>
                </v-list-item-content>
                <v-list-item-action
                  class="d-flex flex-row justify-space-between"
                >
                  <v-dialog
                    fullscreen
                    hide-overlay
                    transition="dialog-bottom-transition"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        :disabled="doc.url == null"
                        small
                        fab
                        elevation="0"
                        class="mr-2 rounded-pill" 
                      >
                        <v-icon>mdi-eye-outline</v-icon>
                      </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                      <v-card
                        v-if="validateDocument(doc.url)"
                        width="20%"
                        height="20%"
                        class="
                          d-flex
                          align-center
                          justify-center
                        "
                      >
                        <v-img
                          :src="doc.url"
                          width="auto"
                          height="auto"
                          max-height="90%"
                          max-width="90%"
                        />
                      </v-card>
                      <embed
                      v-else
                        :src="
                          'https://drive.google.com/viewerng/viewer?embedded=true&url=' +
                          doc.url
                        "
                        style="width: 100%; height: 100%"
                      />
                      <v-btn
                        v-if="$vuetify.breakpoint.smAndDown != true"
                        @click="dialog.value = false"
                        bottom
                        right
                        absolute
                        fab
                        color="accent"
                        style="position: absolute; bottom: 50px; right: 50px"
                        ><v-icon>mdi-close</v-icon></v-btn
                      >
                    </template>
                  </v-dialog>

                  <v-btn
                    :disabled="doc == null"
                    :href="doc.url"
                    fab
                    small
                    elevation="0"
                    class="rounded-pill"
                  >
                    <v-icon> mdi-download-outline </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn
            @click="dialog.value = false"
            elevation="0"
            style="font-size: 18px; font-weight: 800"
            class="text-capitalize rounded-pill"
          >
            <v-icon color="#35404c">mdi-chevron-left</v-icon>
            <span style="color: #35404c" class="pr-2">Volver</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import IconButton from "@/components/shared/IconButton";
import { mapState } from "vuex";
export default {
  components: {
    IconButton,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    documentFields:{
      type: Boolean,
      default:false,
    }
  },
  methods:{
    validateDocument(url){
      const tempUrl = String(url)
      if (tempUrl.includes('.jpg')||tempUrl.includes('.jpeg')||tempUrl.includes('.png')) {
        return true
      }
      return false
    }    
  },
  computed: {
    ...mapState("user", ["user"]),
    documents() {
      if(this.documentFields){
          let regex =
          /^(?:(?:https?|ftp):\/\/)?(?:[\w-]+\.)+[a-z]{2,6}(?:\/[\w- ./?%&=]*)?$/i ;
        let docs = [];
        Object.keys(this.item.attendeeInfo).forEach(e=>{
          if( e!='diplomaUrl'){
            if(regex.test(this.item.attendeeInfo[e])){
              docs.push({
                name: e.replaceAll('_',' '),
                url:this.item.attendeeInfo[e]  
                })
            }else{
              Object.keys(this.item?.attendeeInfo[e] || {}).forEach(e2=>{
                if(regex.test(this.item.attendeeInfo[e][e2])){
                  docs.push({
                    name: e.replaceAll('_',' '),
                    url:this.item.attendeeInfo[e][e2]  
                    })
                }
              })
            }
          }else{

          }
        })
        return docs
      }else{
      let docs = []
      if (this.item.diplomaUrl!=undefined) {
        docs.push({
          name:'Diploma URL',
          url:this.item.diplomaUrl
        })
          return docs        
      }
      return docs
      }
    },
  },
};
</script>

<style>
</style>