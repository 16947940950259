<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-0 d-flex justify-space-between align-center">
      <Pagination :searchByName="false" :searchByLimit="true" :pagination="pagination" @search="search">
        <template v-slot:buttonDowload>
          <action-button iconLeft icon="mdi-download" :class="$vuetify.breakpoint.mdAndDown ? 'mb-8 mt-4' : ''"
            @click="downloadReport()">
            Descargar Reporte
          </action-button>
        </template>
      </Pagination>
    </v-col>
    <v-col cols="12">
      <v-data-table :headers="transactionHeaders" :items="this.ordersFormatted" :loading="loader" item-key="id"
        v-if="this.ordersFormatted" disable-sort disable-pagination hide-default-footer disable-filtering>

        <template v-slot:[`header.id`]="{ header }">
          <span :class="headerClass">
            {{ header.text }}
          </span>
        </template>

        <template v-slot:[`header.name`]="{ header }">
          <span :class="headerClass">
            {{ header.text }}
          </span>
        </template>

        <template v-slot:[`header.date`]="{ header }">
          <span :class="headerClass">
            {{ header.text }}
          </span>
        </template>

        <template v-slot:[`header.time`]="{ header }">
          <span :class="headerClass">
            {{ header.text }}
          </span>
        </template>

        <template v-slot:[`header.event`]="{ header }">
          <span :class="headerClass">
            {{ header.text }}
          </span>
        </template>

        <template v-slot:[`header.total`]="{ header }">
          <span :class="headerClass">
            {{ header.text }}
          </span>
        </template>

        <template v-slot:[`header.payment`]="{ header }">
          <span :class="headerClass">
            {{ header.text }}
          </span>
        </template>

        <template v-slot:[`header.status`]="{ header }">
          <span :class="headerClass">
            {{ header.text }}
          </span>
        </template>

        <template v-slot:[`header.billing`]="{ header }">
          <span :class="headerClass">
            {{ header.text }}
          </span>
        </template>

        <template v-slot:[`item.payment`]="{ value }">
          <span>
            {{ paymentMethod[value] }}
          </span>
        </template>

        <template v-slot:[`item.status`]="{ value }">
          <v-chip label :color="paymentStatuses[value].color">
            <span class="white--text text-uppercase" :class="valueClass">
              {{ paymentStatuses[value].text }}
            </span>
          </v-chip>
        </template>

        <template v-slot:[`item.billing`]="{ value }">
          <!-- <v-btn
            fab
            small
            @click="downloadInvoice(value.invoice)"
            :disabled="
              !value.invoice ||
              ((!user.permissions.reportsDownload ||
                !user.permissions.reportsDownload.update) &&
                user.role == 'ORGANIZATION_USER')
            "
            icon
            elevation="0"
            :color="color.primaryVariant"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn> -->
          <DocumentsDialog :invoiceId="value.invoiceId" :id="value.id" :reference="value.reference" :pdf="example"
            :paymentMethod="value.paymentMethod" @setDocuments="generateDocuments" @getInvoice="downloadInvoice"
            @setEmail="fowardReferenceEmail">
          </DocumentsDialog>
        </template>
      </v-data-table>
    </v-col>
    <v-dialog v-model="reportDialog" width="800" persistent>
      <ReportDialog :reportProgress="reportProgress" :payoutsLength="reportTotalPages" @cancel="cancelReport = true" />
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import {
  getDefaultRangePeriod,
  formatPrice,
  downloadFile,
  downloadLink,
} from "@/utils";
import Endpoints from "@/share/Endpoints";
import AttendeeDocs from "@/components/dashboard/event/AttendeeDocs.vue";
import Pagination from "@/components/dashboard/pagination/Pagination.vue";
import ActionButton from "@/components/shared/ActionButton";
import { colors } from "@/constants/colors";
import moment from "moment";
import ReportDialog from "@/components/shared/Reports/ReportDialog";
import * as XLSX from "xlsx/xlsx.mjs";
import DocumentsDialog from "../../shared/DocumentsDialog.vue";

export default {
  components: {
    AttendeeDocs,
    ActionButton,
    Pagination,
    ReportDialog,
    DocumentsDialog,
  },
  data() {
    return {
      reportDialog: false,
      reportProgress: 0,
      reportTotalPages: 1,
      cancelReport: false,
      dateFilter: false,
      dateFilter2: false,
      example: "",
      loader: false,
      color: colors,
      pagination: {
        page: 1,
        limit: 10,
        totalPages: 0,
        gte: null,
        lte: null,
        name: "",
      },
      searchData: {
        page: 1,
        itemsPerPage: 10,
        gte: null,
        lte: null,
      },
      headerClass: "wc-overline text-primary-variant font-weight-black",
      valueClass: "wc-caption-2 text-primary-variant font-weight-bold",
      transactionHeaders: [
        {
          text: "Id transacción",
          align: "left",
          value: "id",
        },
        {
          text: "Comprador",
          align: "left",
          value: "name",
        },
        {
          text: "Fecha de Compra",
          align: "left",
          value: "date",
        },
        {
          text: "Hora de compra",
          align: "center",
          value: "time",
        },
        {
          text: "Concepto de Compra",
          align: "center",
          value: "event",
        },
        {
          text: "Total",
          align: "center",
          value: "total",
        },
        {
          text: "Método de pago",
          align: "center",
          value: "payment",
        },
        {
          text: "Estatus",
          align: "center",
          value: "status",
        },
        {
          text: "Documentos",
          align: "center",
          value: "billing",
        },
      ],
      paymentMethod: {
        CREDIT_CARD: "Tarjeta de Crédito/Débito",
        DEBIT_CARD: "Tarjeta de Débito",
        BANK_TRANSFER: "Transferencia Bancaria SPEI",
        CASH: "Efectivo (Oxxo)",
        FREE: "Sin costo",
        COUPON: "Cupón",
      },
      coupon: "",
      hasCoupon: false,
      paymentStatuses: {
        COMPLETE: {
          text: "Completado",
          color: "green",
        },
        COMPLETED: {
          text: "Completado",
          color: "green",
        },
        PAYMENT_PENDING: {
          text: "Pendiente",
          color: "accent",
        },
        IN_PROGRESS: {
          text: "En Progreso",
          color: "blue",
        },
        EXPIRED: {
          text: "Expirado",
          color: "red",
        },
        FREE: {
          text: "Cupón",
          color: "blue",
        },
      },
    };
  },
  created() {
    const range = getDefaultRangePeriod();

    const format = "YYYY-MM-DD";
    (this.pagination.gte = moment(range.startDate).format(format)),
      (this.pagination.lte = moment(range.endDate).format(format)),
      this.fetchOrganizationOrders(this.pagination);
  },
  computed: {
    ...mapState("order", ["organizationOrders"]),
    ...mapState("user", ["user"]),
    changeTextColor() {
      return parseInt(this.searchData.page) >
        parseInt(this.organizationOrders.totalPages)
        ? "red"
        : "black";
    },

    ordersFormatted() {
      let orders = [];
      if (
        !this.organizationOrders ||
        !this.organizationOrders.hasOwnProperty("orders")
      )
        return orders;

      this.pagination.totalPages = this.organizationOrders.totalPages;
      let getOrganizationOrders = this.organizationOrders.orders;
      console.log(getOrganizationOrders);
      for (let i = 0; i < getOrganizationOrders.length; i++) {
        const order = getOrganizationOrders[i];
        orders.push({
          ...order,
          id: order._id,
          name: ['INSCRIPTION', 'DEBT'].includes(order.type) ? `${order.member.name} ${order.member.firstLastname}` : order.paymentInformation.name,
          date: this.formatDate(order.sort).date,
          time: this.formatDate(order.createdAt).time,
          coupon: order.coupon,
          event:
            order.event && order.event.name
              ? order.event.name
              : order.affiliation && order.billingInformation
                ? order.billingInformation.description
                : order.affiliation && order.affiliation.name
                  ? order.affiliation.name
                  : order.affiliationInscription
                    ? order.affiliationInscription.description : 'Adeudo',
          total:
            order.total && order.total.hasOwnProperty("$numberDecimal")
              ? formatPrice(order.total.$numberDecimal)
              : formatPrice(order.total),
          payment:
            order.total === 0 && order.coupon !== undefined
              ? "COUPON"
              : order?.paymentInformation?.paymentMethod,

          status: order.status,
          billing: {
            invoiceId: order.invoice,
            needsInvoice: order.paymentInformation.needsInvoice,
            id: order._id,
            paymentMethod:
              order.total === 0 && order.coupon !== undefined
                ? "COUPON"
                : order?.paymentInformation?.paymentMethod,
            reference:
              order.paymentInformation.referenceLink || order.paymentReference
                ? true
                : false,
          },
        });
      }
      // console.log(orders);
      return orders;
    },

    getHeaders() {
      return this.transactionHeaders.map((e) => {
        e.value;
      });
    },
  },
  watch: {
    "searchData.page"(newData) {
      if (!newData) {
        this.searchData.page = 1;
      }
    },
  },
  methods: {
    ...mapActions("order", ["fetchOrganizationOrders"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    search(Pagination) {
      this.pagination = Pagination;
      this.fetchOrganizationOrders(this.pagination);
    },
    async filterOrganizationOrders() {
      await this.fetchOrganizationOrders(this.searchData);
    },
    async increasePageNumber() {
      if (this.organizationOrders.hasOwnProperty("orders")) {
        console.log(this.organizationOrders);
        if (
          this.searchData.itemsPerPage ==
          this.organizationOrders.orders.length &&
          this.searchData.page > 0 &&
          this.searchData.page < this.organizationOrders.totalPages
        ) {
          this.searchData.page = parseInt(this.searchData.page) + 1;
          await this.fetchOrganizationOrders(this.searchData);
        }
      }
    },
    generateDocuments(id) {
      // subir cambios
    },
    async downloadInvoice(id, download = false, type = "") {
      console.log(id, download, type);
      this.example = "";
      try {
        if (type == "reference") {
          const index = this.organizationOrders.orders.findIndex(
            (order) => order._id === id
          );
          let aux = this.organizationOrders.orders[index];
          this.example =
            aux.paymentInformation.referenceLink || aux.paymentReference;
          if (download) {
            downloadLink(this.example);
          }
        } else {
          const payload = {
            orderId: id,
            isPreview: !download,
          };
          this.loading();
          const response = await this.axios.post(
            Endpoints.invoicesDownload,
            payload,
            { responseType: "arraybuffer" }
          );
          if (response.status === 200) {
            const { data } = response;
            if (download) {
              downloadFile(data, "application/zip", "invoice.zip");
            } else {
              console.log(response);
              let blob = new File([data], "invoice.pdf", {
                type: `application/pdf`,
              });
              const fileUrl = URL.createObjectURL(blob);
              this.example = fileUrl;
              console.log(fileUrl);
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loaded();
      }
    },
    async fowardReferenceEmail(id, type) {
      const response = await this.axios({
        method: "POST",
        url: Endpoints.invoicesSendEmail,
        data: {
          orderId: id,
          type: type,
        },
      });
      console.log(response);
      if (response.status === 200) {
        this.show({
          text: "Correo reenviado exitosamente!",
          color: "success",
          time: 3000,
        });
      } else {
        this.show({
          text: "Ha ocurrido un error al reenviar correo!",
          color: "error",
          time: 3000,
        });
      }
    },
    async decreasePageNumber() {
      if (this.organizationOrders.hasOwnProperty("orders")) {
        if (this.searchData.page > 1) {
          this.searchData.page = parseInt(this.searchData.page) - 1;
          await this.fetchOrganizationOrders(this.searchData);
        }
      }
    },
    formatDate(currentDate) {
      const date = new Date(currentDate);
      const dateOptions = { day: "numeric", month: "short", year: "numeric" };
      const timeOptions = { hour: "2-digit", minute: "numeric" };
      let fullDate = date
        .toLocaleDateString("es-MX", dateOptions)
        .replaceAll(" ", "/")
        .toUpperCase();
      let fullTime = date
        .toLocaleTimeString("es-MX", timeOptions)
        .toUpperCase();
      return {
        date: fullDate,
        time: fullTime,
      };
    },

    async downloadReport() {
      this.reportDialog = true;
      this.reportProgress = 0;
      let reportArray = [];
      let hasMoreExpr = true;
      let hasMoreStnd = true;
      let nextPageExpr = null;
      let nextPageStnd = null;
      let totalCount = 0

      while (hasMoreStnd || hasMoreExpr) {
        if (this.cancelReport == true) {
          this.cancelReport = false;
          this.reportDialog = false;
          return;
        }

        const { status,  data: { paymentIntents: { data, next_page_expr, next_page_stnd, has_more_stnd, has_more_expr, total_count } } } = await this.axios({
          url: `/orders/transactions/report?gte=${this.pagination.gte}&lte=${this.pagination.lte
            }&next_page_expr=${nextPageExpr != null ? nextPageExpr : ""}&next_page_stnd=${nextPageStnd != null ? nextPageStnd : ""}&total_count=${totalCount}`,
          method: "GET",
        });
        if (status == 200 && (next_page_stnd || next_page_expr)) {
          reportArray = reportArray.concat(data);
          nextPageExpr = next_page_expr;
          nextPageStnd = next_page_stnd;
          hasMoreStnd = has_more_stnd;
          hasMoreExpr = has_more_expr;
          totalCount = total_count;
          this.reportTotalPages = Math.ceil(
            totalCount / 50
          );
          this.reportProgress++;
        } else if (status == 200 && (has_more_stnd == false && has_more_expr == false)) {
          reportArray = reportArray.concat(data);
          hasMoreExpr = false;
          hasMoreStnd = false;
        } else {
          hasMoreExpr = false;
          hasMoreStnd = false;
        }
      }

      if (this.cancelReport == true) {
        this.cancelReport = false;
        this.reportDialog = false;
        return;
      }
      let formatedArray = reportArray.filter(
        (value, index, self) =>
          index === self.findIndex((t) => value && t.id === value.id)
      ).filter(value => value != null);

      formatedArray.sort((a, b) => {
        let chargeA = new Date(this.formatReportDates(a.fecha_transaccion))
        let chargeB = new Date(this.formatReportDates(b.fecha_transaccion))
        return chargeB - chargeA
      })

      const ws = XLSX.utils.json_to_sheet(formatedArray);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "UltimasTransacciones");
      XLSX.writeFile(wb, "UltimasTransacciones.xlsx");

      this.reportDialog = false;
    },

    formatReportDates(date) {
      let newFormat = (date.split(",")[0]).split("/")

      return `${newFormat[1]}/${newFormat[0]}/${newFormat[2]}, ${date.split(",")[1]}`
    }
  },
  async mounted() {
    /*const range = getDefaultRangePeriod();
    const format = "YYYY-MM-DD";
    this.searchData.gte = moment(range.startDate).format(format)
    this.searchData.lte = moment(range.endDate).format(format)
    console.log(this.searchData);
    await this.fetchOrganizationOrders(this.searchData);*/
  },
};
</script>

<style scoped>
.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.input-number {
  width: 20px;
  margin-right: 6px;
  text-align: center;
}

.input-number:focus {
  outline: none;
}

.no-wrap {
  white-space: nowrap;
}
</style>
