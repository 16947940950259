<template>
  <v-row justify="center" class="my-0 py-4">
    <Chat></Chat>
    <v-col cols="11" class="white rounded-xl">
      <v-row class="ma-0">
        <v-col cols="12" lg="6">
          <CreditBar />
        </v-col>

        <v-col cols="12" lg="6">
          <v-row justify="space-around" no-gutters>
            <v-col cols="12" md="5" class="my-2">
              <v-card class="rounded-xl" elevation="0" color="#9bf4d5" rounded>
                <v-row class="ma-0 py-4 text-center h-100" justify="center">
                  <span class="text-right">
                    <span class="wc-overline text-primary-variant">
                      Miembros Activos
                    </span>
                    <br />
                    <span class="wc-subtitle-1 text-secondary text-left">
                      {{ formatNumber(activeMembers, false) }}
                    </span>
                  </span>
                </v-row>
              </v-card>
            </v-col>

            <v-col cols="12" md="5" class="my-2">
              <v-card
                class="rounded-xl"
                elevation="0"
                color="light-blue lighten-4"
                rounded
              >
                <v-row class="ma-0 py-4 text-center h-100" justify="center">
                  <span class="text-right">
                    <span class="wc-overline text-primary-variant">
                      Total de Ventas
                    </span>
                    <br />
                    <span class="wc-subtitle-1 text-primary-variant text-left">
                      ${{ formatNumber(totalIncome) }} MXN
                    </span>
                  </span>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row class="ma-0 mt-5">
        <v-col>
          <span class="wc-h6 text-primary-variant">Estadísticas</span>
        </v-col>
      </v-row>

      <v-row class="ma-0 mt-2" justify="space-around">
        <v-col cols="12" sm="6" md="5" lg="4">
          <v-row justify="center" class="ma-0 mb-2">
            <span class="wc-subtitle-2 text-primary-variant font-weight-black"
              >Ingresos por Eventos</span
            >
          </v-row>
          <BarChart :chartData="eventsChartData" />
        </v-col>

        <v-col cols="12" sm="6" md="5" lg="4">
          <v-row justify="center" class="ma-0 mb-2">
            <span class="wc-subtitle-2 text-primary-variant font-weight-black"
              >Ingresos por Membresías</span
            >
          </v-row>
          <LineChart :chartData="membershipChartData" />
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row class="ma-0 mt-2" justify="space-around">
        <v-col cols="12" sm="6" md="5" lg="4">
          <v-row justify="center" class="ma-0 mb-2">
            <span class="wc-subtitle-2 text-primary-variant font-weight-black"
              >Estatus de mis Miembros</span
            >
          </v-row>
          <PieChart :chartData="statusChartData" />
        </v-col>

        <v-col cols="12" sm="6" md="5" lg="4">
          <v-row justify="center" class="ma-0 mb-2">
            <span class="wc-subtitle-2 text-primary-variant font-weight-black"
              >Tipos de Membresía</span
            >
          </v-row>
          <PieChart :chartData="statusChartData2" />
        </v-col>
      </v-row>

      <v-divider></v-divider>

      <v-row class="ma-0 mt-5">
        <v-col>
          <!-- <span class="wc-h6 text-primary-variant">Últimos eventos</span> -->
          <span class="wc-h6 text-primary-variant">Últimas transacciones</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <TransactionListNew />
          <!-- <EventsTable />  -->
        </v-col>
      </v-row>
    </v-col>
  </v-row>  
</template>

<script>
import Chat from "@/components/shared/Chat.vue";
import { colors } from "@/constants/colors.js";
import BarChart from "@/components/dashboard/home/BarChart.vue";
import LineChart from "@/components/dashboard/home/LineChart.vue";
import PieChart from "@/components/dashboard/home/PieChart.vue";
import EventsTable from "@/components/dashboard/home/EventsTable.vue";
import CreditBar from "../../components/dashboard/creditBar.vue";
import { mapState, mapMutations, mapActions } from "vuex";
import Vue from "vue";
import TransactionListNew from "../../components/dashboard/home/TransactionListNew.vue";

export default {
  name: "HomePanel",
  components: {
    CreditBar,
    BarChart,
    LineChart,
    PieChart,
    TransactionListNew,
    EventsTable,
    Chat,
  },
  data() {
    return {
      color: colors,
      eventsDataset: [
        {
          label: "2023",
          backgroundColor: colors.primaryVariant,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      membershipsDataset: [
        {
          label: "2023",
          backgroundColor: colors.primaryVariant,
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        },
      ],
      countMemberAffiliations: 0,
      totalIncome: 0,
      activeMembers: 0,
      inactiveMembers: 0,
      prospectMembers: 0,
      expiredMembers: 0,
      totalSubmembers: 0,
      activeSubmembers: 0,
      totalMembers: 0,
      affiliationTypes: [],
      counters: [],

      dataColors: ["#1b273e", "#fcc230", "#5cf3be", "#f18f5e"],
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    eventsChartData() {
      return {
        labels: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        datasets: this.eventsDataset,
      };
    },
    membershipChartData() {
      return {
        labels: [
          "Enero",
          "Febrero",
          "Marzo",
          "Abril",
          "Mayo",
          "Junio",
          "Julio",
          "Agosto",
          "Septiembre",
          "Octubre",
          "Noviembre",
          "Diciembre",
        ],
        datasets: this.membershipsDataset,
      };
    },
    statusChartData() {
      return {
        labels: ["Activos", "Inactivos", "Prospectos", "Expirados"],
        datasets: [
          {
            label: "Miembros por estatus",
            backgroundColor: ["#7dd6b3", "#f8b89a", "#f9d26e", "#d9d9d9"],
            data: [
              this.activeMembers,
              this.inactiveMembers,
              this.prospectMembers,
              this.expiredMembers,
            ],
          },
        ],
      };
    },

    statusChartData2() {
      /* if ((this.totalSubmembers > 0) && (this.countMemberAffiliations > 1)) {
        console.log("1")
      return {

        labels: this.affiliationTypes.concat(["Miembros", "Submiembros"]),
        datasets: [
          {
            label: "Miembros por estatus",
            backgroundColor: ["#d4af37", "#868686 ", "#f9d26e", "#d9d9d9"],
            data: this.counters.concat([this.activeMembers, this.activeSubmembers])
          },
        ],
      };
    }else */ if (this.activeSubmembers > 0) {
        // console.log("2")
        return {
          labels: ["Miembros", "Submiembros"],
          datasets: [
            {
              label: "Miembros por estatus",
              backgroundColor: ["#d4af37", "#868686", "#f9d26e", "#d9d9d9"],
              data: [this.activeMembers, this.activeSubmembers],
            },
          ],
        };
      } else {
        // console.log("3")
        return {
          labels: ["Miembros"],
          datasets: [
            {
              label: "Miembros por estatus",
              backgroundColor: ["#d4af37", "#868686", "#f9d26e", "#d9d9d9"],
              data: [this.activeMembers],
            },
          ],
        };
      }
    },
  },
  methods: {
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapActions("user", ["getIndicators"]),
    async fetchGraphData() {
      try {
        this.loading();
        const response = await this.axios.get("/users/graph-indicators");
        if (response.status === 200) {
          this.formatIndicatorsData(response.data.indicators);
        }
      } catch (error) {
        console.log(error);
        this.loaded();
      }
    },
    formatIndicatorsData(data) {
      let eventData = {};
      let eventDataArray = [];
      let membershipData = {};
      let membershipDataArray = [];
      const year = new Date().getFullYear();
      const currentYearIndex = data.monthlyIncome.findIndex(element => element._id == year )
      const defaultObject = {
        label: "",
        backgroundColor: "",
        data: Array(12).fill(0),
      };

      for (const item of data.monthlyIncome) {
        eventData[item._id] = JSON.parse(JSON.stringify(defaultObject));
        eventData[item._id].label = item._id;
        eventData[item._id].backgroundColor =
        this.dataColors[Object.keys(eventData).length - 1];
        membershipData[item._id] = JSON.parse(JSON.stringify(defaultObject));
        membershipData[item._id].label = item._id;
        membershipData[item._id].backgroundColor =
          this.dataColors[Object.keys(eventData).length - 1];
        for (const { month, totalEvents, totalAffiliations } of item.months) {
          eventData[item._id].data[month - 1] = totalEvents
          membershipData[item._id].data[month - 1] = totalAffiliations
        }
        console.log(JSON.stringify(eventData));
      }

      for (const key in eventData) {
        eventDataArray.push(eventData[key]);
      }
      this.eventsDataset = eventDataArray;

      for (const key in membershipData) {
        membershipDataArray.push(membershipData[key]);
      }
      this.membershipsDataset = membershipDataArray;

      this.totalIncome = data.monthlyIncome[currentYearIndex]?.total || 0;
      this.activeMembers = data.activeMembers;
      this.inactiveMembers = data.inactiveMembers;
      this.prospectMembers = data.prospectMembers;
      this.expiredMembers = data.expiredMembers;
      //this.counters = data.numericValues;
      (this.totalMembers = data.totalMembers),
        (this.totalSubmembers = data.totalSubmembers),
        // this.affiliationTypes = data.affiliationTypes;
        (this.activeSubmembers = data.activeSubmembers);
      // this.countMemberAffiliations = data.memberAffiliationTypes;
      // console.log(this.countMemberAffiliations);
      //

      this.loaded();
    },
    //
    formatNumber(number, showDecimals = true) {
      const roundedNumber = showDecimals
        ? Math.round(number * 100) / 100
        : Math.round(number);

      const formattedNumber = roundedNumber
        .toFixed(showDecimals ? 2 : 0)
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return formattedNumber;
    },
  },
  async created() {
    await this.fetchGraphData();
    await this.getIndicators();
  },
  mounted() {
    this.$gtag.screenview({
      app_name: "App",
      screen_name: `${this.user.role} Landing Page`,
    });
  },
};
//t
</script>

<style>
.rounded-progress {
  border-radius: 20px;
}

.analytic-card {
  height: 90px;
}

.pie-chart-container {
  max-width: 100px;
  /* Ajusta el ancho máximo según tus necesidades */
  margin: 0 auto;
  /* Centra el contenedor del gráfico */
}
</style>
